import React from 'react'
import { Grid, Typography } from '@mui/material'
import useStyles from './Style'
import Image from 'next/image'
import Emoji from 'src/assets/svgs/emoji.svg'
import { ButtonComponent } from 'src/components/shared/atoms'
import clsx from 'clsx'
import { useRouter } from 'next/router'
interface btnData {
  id: number
  content: string
  href: string
}
interface confidentialSupportProps {
  className?: string
  content: {
    heading: string
    para: string
    btnDetail: btnData[]
  }
  zenMode: boolean
}
const ConfidentialSupport: React.FC<confidentialSupportProps> = ({
  className,
  content,
  zenMode,
}) => {
  const { classes } = useStyles()
  const { heading, para, btnDetail } = content

  const router = useRouter()
  return (
    <Grid className={classes.rootContainer}>
      <Grid
        container
        className={clsx(
          classes.confidentialContainer,
          className,
          zenMode && classes.zenModeStyle
        )}
      >
        {!zenMode && <Grid className={classes.noiseBg}></Grid>}
        <Grid
          container
          item
          md={zenMode ? 11 : 7}
          sm={12}
          sx={{
            position: 'relative',
            justifyContent: { xs: 'center', md: 'flex-start' },
            textAlign: { xs: 'center', md: zenMode ? 'center' : 'left' },
            order: { xs: 2, sm: 2, md: 1 },
          }}
        >
          <Grid container>
            <Grid item>
              <Typography
                variant="h3"
                className={clsx(
                  classes.confidentialHeading,
                  zenMode && classes.zenModeHeading
                )}
              >
                {heading}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                className={clsx(
                  classes.confidentialPara,
                  zenMode && classes.zenModePara
                )}
              >
                {para}
              </Typography>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: {
                  xs: 'center',
                  sm: 'center',
                  md: 'flex-start',
                },
              }}
            >
              {btnDetail && btnDetail.length
                ? btnDetail.map((curr, index) =>
                    !curr.content.toLowerCase().includes('learn ') ? (
                      <Grid
                        key={index}
                        item
                        className={classes.getSupportBtnContainer}
                      >
                        <ButtonComponent
                          type="text"
                          text={curr.content}
                          className={classes.getSupportBtn}
                          onClick={() =>
                            router.push(curr.href ? curr.href : '#')
                          }
                        />
                      </Grid>
                    ) : (
                      <Grid
                        key={index}
                        item
                        sx={{
                          display: { xs: 'none', sm: 'block', md: 'block' },
                        }}
                      >
                        <ButtonComponent
                          type="text"
                          text={curr.content}
                          className={classes.learnMoreBtn}
                          onClick={() =>
                            router.push(curr.href ? curr.href : '/')
                          }
                        />
                      </Grid>
                    )
                  )
                : ''}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          md={5}
          justifyContent="center"
          alignItems="center"
          sm={12}
          sx={{ order: { xs: 1, sm: 1, md: 2 }, position: 'relative' }}
        >
          <Grid>
            <Image className={classes.EmojiImg} src={Emoji} alt="emoji" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConfidentialSupport
