/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import { Grid, List, ListItemButton, ListItemText } from '@mui/material'
import useStyles from './Style'
import ZenToggleBar from '../zenToggleBar'
import ShareArticle from '../shareArticle'
import clsx from 'clsx'
import rehypeSlug from 'rehype-slug'
import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux'
import { zenModeHandler } from 'src/store/reducer/zenSlice'
import slugify from 'slug-generator'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { getCookie } from 'cookies-next'
import rehypeRaw from 'rehype-raw'

interface MainContentProps {
  content: string
  articleGif:string
  zenMode: boolean
}

const MainContent: React.FC<MainContentProps> = ({ content, zenMode ,articleGif }) => {
  const [selectedIndex, setSelectedIndex] = React.useState('')
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const router = useRouter()
  const [listData, setListData] = useState<{ content: string; href: string }[]>(
    []
  )
  const lang = getCookie('lang')

  useEffect(() => {
    if (content != '') {
      // const regex = /^###.*\n/gm
      // const regex = /^#{1,3}.*\n/gm
      // const list = content.match(regex)
      const parser = new DOMParser()
      const doc = parser.parseFromString(content, 'text/html')
      const list: string[] = []
      const h1Elements = doc.querySelectorAll('h1')
      const h2Elements = doc.querySelectorAll('h2')
      const h3Elements = doc.querySelectorAll('h3')

      h1Elements.forEach(
        (element) => element.textContent && list.push(element.textContent)
      )
      h2Elements.forEach(
        (element) => element.textContent && list.push(element.textContent)
      )
      h3Elements.forEach(
        (element) => element.textContent && list.push(element.textContent)
      )

      if (list && list.length) {
        const finalList = list.map((curr) => {
          const href = slugify(curr)
          return { content: curr, href: `#${href}` }
        })
        setListData(finalList)
      }
    }
  }, [content])

  const handleListItemClick = (href: string) => {
    setSelectedIndex(href)

    const element = document.getElementById(href.replace('#', ''))
    if (typeof window === 'object' && element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + -150
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }
  const addControlsToVideos = () => {
    const parser = new DOMParser()
    const serializer = new XMLSerializer()
    const doc = parser.parseFromString(content, 'text/html')

    const videoElements = doc.querySelectorAll('video')

    videoElements.forEach((video) => {
      video.setAttribute('controls', 'true')
    })

    return serializer.serializeToString(doc)
  }

  const children = addControlsToVideos()
  const listContent = () => {
    return listData
      ? listData.length &&
          listData.map(
            (el: { content: string; href: string }, index: number) => (
              <Grid key={index}>
                {' '}
                <ListItemButton
                  className={classes.listItemButton}
                  selected={selectedIndex === el.href}
                  onClick={() => handleListItemClick(el.href)}
                >
                  <ListItemText
                    primary={el?.content.replace(/[^a-zA-Z0-9 ]/g, '')}
                  />
                </ListItemButton>
              </Grid>
            )
          )
      : ''
  }
  const handleClick = () => {
    dispatch(zenModeHandler())
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rehypeRawDec:any = rehypeRaw;
  return (
    <Grid
      container
      className={classes.rootContainer}
      justifyItems="flex-start"
      alignItems="flex-start"
    >
      <Grid
        item
        container
        md={2}
        sx={{
          display: { md: 'flex', xs: 'none' },
        }}
        className={classes.leftPanelContainer}
      >
        <List
          sx={{
            wordBreak: 'break-word',
          }}
        >
          {listContent() === 0 ? '' : listContent()}
        </List>
        <Grid container item className={classes.zenModeContainer}>
          <ZenToggleBar handleClick={handleClick} />
        </Grid>
        <Grid item>
          <ShareArticle zenMode={zenMode} />
        </Grid>
      </Grid>
      <Grid item container md={7} sm={12}>
        <Grid className={clsx(classes.midPanelContainer, zenMode && `zenMode`)}>
          <ReactMarkdown rehypePlugins={[rehypeSlug, rehypeRawDec]}>
            {children}
          </ReactMarkdown>
        </Grid>
      </Grid>
      <Grid
        item
        container
        md={3}
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Grid
          item
          sx={{
            display: {
              md: zenMode ? 'none' : 'flex',
              xs: 'none',
            },
          }}
          className={classes.AdContainer}
        >
          {lang === 'En' ? (
            <Image
              onClick={() => {
                router.push('https://forums.ditchthelabel.org/')
              }}
              src={articleGif ?? ""}
              width={358}
              height={890}
              style={{ cursor: 'pointer' }}
              alt="gif"
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MainContent
