import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
const useStyles = makeStyles()((theme) => ({
  heroContainer: {
    width: '100%',
    padding: theme.spacing(10, 17.5, 0, 17.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
    background:
      'linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0) 91.2%)',
    position: 'relative',
  },
  zenModeStyle: {
    background: 'none',
  },
  articleCobrandOnZenMode: {
    top: 90,
    left: -70,
    position: 'absolute',
    width: '196px',
    height: '60px',
    background: 'white',
    alignItems: 'center',
    padding: theme.spacing(2),
    transform: 'rotate(90deg)',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.07)',
    '& img': {
      display: 'flex',
    },
  },

  articleCobrandOnZenModeImg: {
    height: '50px',
    transform: 'rotate(-90deg)',
  },
  articleCobrandOnZenModePara: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '13px',
  },
  heroImgContainer: {
    position: 'relative',
    width: '100%',
    height: '455px',
    borderRadius: '40px',
    overflow: 'hidden',
    objectFit: 'cover',
    '& img': {
      objectFit: 'cover',
    },
    [theme.breakpoints.down('md')]: {
      height: '288px',
    },
  },
  heroImg: {
    width: '100%',
  },
  dateContainer: {
    marginBottom: theme.spacing(8),
  },
  date: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21.82px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '16.37px',
    },
  },
  tagContainer: {
    marginBottom: theme.spacing(8),
  },
  articleHeadingContainer: {
    marginBottom: theme.spacing(8),
  },
  articleHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 800,
    fontSize: '48px',
    lineHeight: '65.4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      lineHeight: '38.19px',
    },
  },
  autherContainer: {},
  auther: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16.3px',
  },
  authorImgContainer: {
    position: 'relative',
    width: '67px',
    height: '67px',
    borderRadius: '50%',
    overflow: 'hidden ',
  },
  autherImg: {},
  autherName: {
    cursor: 'pointer',
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24.55px',
  },

  articleCobrand: {
    display: 'flex',
    width: '75%',
    margin: theme.spacing(3, 0),
    borderTop: '1px solid rgba(0, 0, 0, 0.4)',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('md')]: {
      border: 0,
      width: '100%',
    },
  },
  borderBottom: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
  },
  articleCobrandText: {
    fontFamily: nunito_sans.style.fontFamily,
    width: '75%',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '13px',
    [theme.breakpoints.down('md')]: {
      border: 0,
      margin: 'auto',
    },
  },
  dtlLogo: {
    width: '91.66px',
    height: '56.95px',
  },
  simpleLogo: {
    width: '89.7px',
    height: '52.88px',
  },
}))

export default useStyles
