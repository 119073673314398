import React, { useEffect, useState } from 'react'
import { Grid, InputAdornment, Typography } from '@mui/material'
import useStyles from './Style'
import { ButtonComponent, RoundTextField } from 'src/components/shared/atoms'
import CommentPolicy from 'src/assets/svgs/icons/articleDetail/commentPolicy'
import Emoji from 'src/assets/svgs/icons/articleDetail/emoji'
// import FileAttachment from 'src/assets/svgs/icons/articleDetail/fileAttachment'
import DownArrowIcon from 'src/assets/svgs/icons/downArrow'
import CommentBody from './commentBody'
// import InputEmoji from 'react-input-emoji'
import { useCreateCommentMutation } from 'src/store/ApiCall/commentAPI'
import { GetCommentsInterface } from 'src/types/store'
// import { getImageUrl } from 'src/utils'
import EmojiPicker, { Theme } from 'emoji-picker-react'
import { useRouter } from 'next/router'
import orderBy from 'lodash.orderby'
import aa from 'search-insights'
import { getCookie } from 'cookies-next'
import { useUpdateArticlesMutation } from 'src/store/ApiCall/articleAPI'

interface IProps {
  data: GetCommentsInterface[]
  articleId: number
  comments_count: number
}
export interface CommentClientInterface {
  id: number
  content: string
  articleId: number
  parentId: number | undefined | null
  comment_author: {
    id: number
    avatar: string
    username: string
  }
  userWithReaction: {
    user: {
      id: number
      name: string
    }
    reaction: {
      emoji: string
      id: number
      unified: string
    }
  }[]
  type: string
  publishedAt: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any[] | []
}

const CommentWithReply: React.FC<IProps> = ({
  data,
  articleId,
  comments_count,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loggedInUser, setLoggedInUser] = React.useState<any>()
  const [commentsCount, setCommentsCount] = useState(comments_count)
  const [updateArticle] = useUpdateArticlesMutation()

  React.useEffect(() => {
    if (comments_count) {
      setCommentsCount(comments_count)
    }
  }, [comments_count])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function updateArticleHandler(data: any) {
    const response = await updateArticle(data).unwrap()
    return response
  }
  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON =
        typeof window === 'object' ? localStorage.getItem('dtl_token') : null

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON))
      }
    }
  }, [])

  const [lang, setLang] = React.useState<string>()
  React.useEffect(() => {
    if (
      typeof window === 'object' &&
      (window.location.href.includes('mx') ||
        window.location.href.includes('quit') ||
        window.location.href.includes('quiat'))
    ) {
      localStorage.setItem('lang', 'Es')
      setLang('Es')
    } else {
      localStorage.setItem('lang', 'En')
      setLang('En')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window === 'object' ? window.location.href : null])
  const router = useRouter()
  const { classes } = useStyles()
  const [inputStr, setInputStr] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [createComment] = useCreateCommentMutation()
  const postComment = async (data: {
    data: {
      content: string
      comment_author: number
      article: number
    }
  }) => {
    return await createComment(data).unwrap()
  }

  useEffect(() => {
    if (data && data.length) {
      const commentFinalContent: CommentClientInterface[] = []
      data.map(async (curr) => {
        const {
          id,
          content,
          comment_author,
          article,
          publishedAt,
          parent_comment,
          comments,
          user_comment_emojis,
        } = curr

        parent_comment?.id == null &&
          commentFinalContent.push({
            id: id,
            content: content,
            publishedAt: publishedAt,
            type: 'parent',
            parentId: parent_comment?.id,
            comment_author: {
              id: comment_author.id,
              avatar: comment_author.avatar ? comment_author.avatar : '',
              username: comment_author.username,
            },
            // children: comments.length ? recursiveData(comments) : [],
            children: comments.map((curr) => curr.id),
            articleId: article.id,
            userWithReaction: user_comment_emojis.map((curr) => {
              return {
                user: {
                  id: curr.dtl_user.id,
                  name: curr.dtl_user.username,
                },
                reaction: {
                  emoji: curr.reaction.emoji,
                  id: curr.reaction.id,
                  unified: curr.reaction.unified,
                },
              }
            }),
          })
      })

      setInputStr('')
      if (commentFinalContent.length) {
        setCommentArray(orderBy(commentFinalContent, ['publishedAt'], ['desc']))
      }
    }
  }, [data])
  const [commentArray, setCommentArray] = useState<CommentClientInterface[]>([])

  const handleClick = async () => {
    if (!loggedInUser) {
      //router push /login page
      router.push('/auth/login')
      return
    }
    if (inputStr && inputStr.length) {
      const commentArr: CommentClientInterface[] | undefined = commentArray
      const data = {
        data: {
          content: inputStr,
          comment_author: loggedInUser.id,
          article: articleId,
        },
      }
      const commentData = await postComment(data)
      if (commentData) {
        const articleRes = await updateArticleHandler({
          data: {
            id: articleId,
            article_comment_count: commentsCount + 1,
          },
        })
        if (articleRes) {
          setCommentsCount(commentsCount + 1)
        }
        aa('setUserToken', loggedInUser.toString())
        aa('convertedObjectIDs', {
          userToken: '' + loggedInUser.id.toString(),
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'article_comment',
          objectIDs: [articleId + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })

        commentArr &&
          commentArr.unshift({
            id: commentData.data.id,
            content: inputStr,
            type: 'parent',
            comment_author: {
              id: loggedInUser.id,
              avatar: '',
              username: loggedInUser.username,
            },
            children: [],
            parentId: null,
            publishedAt: commentData.data.attributes.createdAt,
            articleId: articleId,
            userWithReaction: [],
          })

        setInputStr('')
        setCommentArray(commentArr)
      }
    }
  }

  const onEmojiClick = (e: { emoji: string }) => {
    setInputStr((prev) => prev + e.emoji)
  }

  return (
    <Grid className={classes.rootContainer}>
      <Grid container className={classes.commentContainer}>
        <Grid container item className={classes.commentHeader}>
          <Typography variant="h3" className={classes.commentTypo}>
            {lang === 'En' ? 'COMMENTS' : 'COMENTARIOS'}
          </Typography>
          <Grid sx={{ display: { md: 'flex', sm: 'none', xs: 'none' } }}>
            <ButtonComponent
              type="contained"
              text={
                lang === 'En'
                  ? 'View privacy policy'
                  : 'VER POLÍTICA DE PRIVACIDAD'
              }
              onClick={() => router.push('/legal')}
              startIcon={<CommentPolicy />}
              className={classes.viewPolicyBtn}
            />
          </Grid>
          <Grid sx={{ display: { md: 'none', sm: 'flex', xs: 'flex' } }}>
            <ButtonComponent
              type="contained"
              text=""
              startIcon={<CommentPolicy />}
              className={classes.viewPolicyBtn}
            />
          </Grid>
        </Grid>
        <Grid container item className={classes.commentInputFieldContainer}>
          <RoundTextField
            variant="outlined"
            fullWidth
            placeholder={
              lang === 'En' ? 'Join the discussion...' : 'UNIRSE A LA DISCUSIÓN'
            }
            className={classes.roundTextFieldRoot}
            value={inputStr}
            onChange={(e) => setInputStr(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Grid
                    sx={{ position: 'relative' }}
                    className={classes.roundTextFieldIconContainer}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    <Emoji />
                    {showEmojiPicker ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          zIndex: 100000,
                          right: 10,
                          top: 45,

                          '& .EmojiPickerReact': {
                            left: {
                              xs: '120px !important',
                            },
                            width: {
                              xs: '260px !important',
                              sm: '320px !important',
                              md: '350px !important',
                              lg: '350px',
                            },
                            height: {
                              xs: '400px !important',
                              sm: '400px !important',
                              md: '450px !important',
                              lg: '450px',
                            },
                            '& .epr-main': {
                              position: 'absolute !important',
                              zIndex: 10,
                            },
                          },
                        }}
                      >
                        <EmojiPicker
                          onEmojiClick={(e) => onEmojiClick(e)}
                          theme={Theme.DARK}
                          searchDisabled={true}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <ButtonComponent
                    className={classes.publishBtn}
                    text={lang === 'En' ? 'PUBLISH' : 'PUBLICAR'}
                    type="text"
                    onClick={handleClick}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid container item className={classes.commentRepliesContainer}>
          <Grid container item className={classes.recentContainer}>
            <Typography variant="h5" className={classes.recentHeading}>
              {lang === 'En' ? 'MOST RECENT' : 'MÁS RECIENTES'}{' '}
              <span>
                <DownArrowIcon />
              </span>
            </Typography>
          </Grid>
          {commentArray && commentArray.length
            ? commentArray.map((curr, index) => (
                <CommentBody key={index} comment={curr} />
              ))
            : lang === 'En'
            ? 'no comments'
            : 'SIN COMENTARIOS'}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CommentWithReply
