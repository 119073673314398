import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
const useStyles = makeStyles()((theme) => ({
  shareArticleContainer: {
    padding: theme.spacing(7, 0),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  shareArticleText: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '26.88px',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginRight: '0px',
    },
  },
  fbLogo: {
    width: '32px',
    height: '32px',
    display: 'block !important',
  },
  twitterLogo: {
    width: '32px',
    height: '32px',
    display: 'block !important',
  },
  shareLinkLogo: {
    width: '32px',
    height: '32px',
    display: 'block !important',
    cursor: 'pointer',
  },
}))

export default useStyles
