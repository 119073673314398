import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  articleDetailContainer: {
    paddingTop: '30px',
  },
  gradientContainer: {
    background:
      'linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0) 91.2%);',
    transform: 'rotate(180deg)',
    '&>*': {
      transform: 'rotate(180deg)',
    },
  },
  meetNewFriendContainer: {
    padding: theme.spacing(0, 17.5, 0, 17.5),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
}))

export default useStyles
