/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Avatar, Grid, InputAdornment, Typography } from '@mui/material'
import useStyles from './Style'
import Moment from 'react-moment'
import CustomMenu from 'src/components/shared/atoms/menu'
import { ButtonComponent, RoundTextField } from 'src/components/shared/atoms'
import Emoji from 'src/assets/svgs/icons/articleDetail/emoji'
import EmojiPicker, { Theme } from 'emoji-picker-react'

import HorizontalIcon from 'src/assets/svgs/icons/articleDetail/horizontalIcon'
import {
  useAddUserCommentEmojiMutation,
  useCreateCommentMutation,
  useCreateEmojiMutation,
  useDeleteMulipleCommentsMutation,
  useDeleteUserCommentEmojiMutation,
  useEditCommentMutation,
  useFindEmojiMutation,
} from 'src/store/ApiCall/commentAPI'
import { CommentClientInterface } from '..'
import EmojiContainer from './emojiContainer/EmojiContainer'
import { useRouter } from 'next/router'
import { useGetCommentsMutation } from 'src/store/ApiCall/articleAPI'
import aa from 'search-insights'
import { getCookie } from 'cookies-next'

interface commentBodyProps {
  comment: CommentClientInterface
}
const CommentBody: React.FC<commentBodyProps> = ({ comment }) => {
  const router = useRouter()
  // const loggedInUserJSON = localStorage.getItem('dtl_token')
  const [getComments] = useGetCommentsMutation()

  async function getCommentsDetail(idsArr: { id: number[] }) {
    const data = await getComments(idsArr).unwrap()
    const childCommentArr: CommentClientInterface[] = []
    if (data && data.length) {
      data.map((curr) => {
        const {
          id,
          content,
          comment_author,
          article,
          publishedAt,
          parent_comment,
          user_comment_emojis,
          comments,
        } = curr

        childCommentArr.push({
          id: id,
          content: content,
          publishedAt: publishedAt,
          type: 'child',
          parentId: parent_comment?.id,
          comment_author: {
            id: comment_author.id,
            avatar: comment_author.avatar ? comment_author.avatar : '',
            username: comment_author.username,
          },
          children: comments.length ? comments.map((curr) => curr.id) : [],
          articleId: article.id,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          userWithReaction: user_comment_emojis.map((curr: any) => {
            return {
              user: {
                id: curr.dtl_user?.id,
                name: curr.dtl_user?.username,
              },
              reaction: {
                emoji: curr.reaction?.emoji,
                id: curr.reaction?.id,
                unified: curr.reaction?.unified,
              },
            }
          }),
        })
      })
    }

    setChildComment(childCommentArr.reverse())
  }
  // let loggedInUser: { id: number; username: string } | undefined = undefined

  // if (loggedInUserJSON) {
  //   loggedInUser = JSON.parse(loggedInUserJSON)
  // }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loggedInUser, setLoggedInUser] = React.useState<any>()

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON =
        typeof window === 'object' ? localStorage.getItem('dtl_token') : null

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  const [commentData, setCommentData] =
    useState<CommentClientInterface>(comment)

  useEffect(() => {
    if (comment) {
      setCommentData(comment)
    }
  }, [comment])

  const [lang, setLang] = React.useState<string>()
  React.useEffect(() => {
    if (
      typeof window === 'object' &&
      (window.location.href.includes('mx') ||
        window.location.href.includes('quit') ||
        window.location.href.includes('quiat'))
    ) {
      localStorage.setItem('lang', 'Es')
      setLang('Es')
    } else {
      localStorage.setItem('lang', 'En')
      setLang('En')
    }
  }, [typeof window === 'object' ? window.location.href : null])

  const {
    id,
    content,
    type,
    comment_author,
    articleId,
    userWithReaction,
    publishedAt,
  } = commentData
  useEffect(() => {
    if (userWithReaction && userWithReaction.length) {
      const emojiMap: {
        [key: string]: {
          emoji: { name: string; unified: string }
          users: { userId: number }[]
        }
      } = {}

      userWithReaction.forEach((item) => {
        const emojiName = item.reaction.emoji
        const userId = item.user.id

        if (!emojiMap[emojiName]) {
          emojiMap[emojiName] = {
            emoji: { name: emojiName, unified: item.reaction.unified },
            users: [],
          }
        }

        emojiMap[emojiName].users.push({ userId: userId })
      })

      const result = Object.values(emojiMap)
      setEmojiWithUser(result)
    } else setEmojiWithUser([])
  }, [userWithReaction])

  useEffect(() => {
    if (comment.children && comment.children.length) {
      getCommentsDetail({ id: comment.children })
    }
  }, [comment])
  const { classes } = useStyles()
  const [replyActive, setReplyActive] = useState(false)
  const [inputStr, setInputStr] = useState('')
  const [emojiPickerShown, setEmojiPickerShown] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [emojiWithUser, setEmojiWithUser] = useState<
    { emoji: { name: string; unified: string }; users: { userId: number }[] }[]
  >([])
  const [createComment] = useCreateCommentMutation()
  const [editComment] = useEditCommentMutation()
  const [findEmoji] = useFindEmojiMutation()
  const [createEmoji] = useCreateEmojiMutation()
  const [addNewUserReaction] = useAddUserCommentEmojiMutation()
  const [childComment, setChildComment] = useState<CommentClientInterface[]>([])
  const [deleteNewUserReaction] = useDeleteUserCommentEmojiMutation()
  const [deleteMultipleComments] = useDeleteMulipleCommentsMutation()
  let copyEmojiWithUser = []
  const postComment = async (data: {
    data: {
      content: string
      comment_author: number
      article: number
      parent_comment: number
    }
  }) => {
    return await createComment(data).unwrap()
  }

  useEffect(() => {
    if (editMode) {
      setInputStr(content)
    } else {
      setInputStr('')
    }
  }, [editMode])

  const updateComment = async (data: {
    data: {
      id: number
      content: string
    }
  }) => {
    return await editComment(data).unwrap()
  }

  const delMultipleComments = async (data: { id: number }) => {
    return await deleteMultipleComments(data)
  }
  const findEmojiHandler = async (data: string) => {
    return await findEmoji(data).unwrap()
  }
  const createEmojiHandler = async (data: {
    data: {
      emoji: string
      unified: string
    }
  }) => {
    return await createEmoji(data).unwrap()
  }

  const addNewUserReactionHandler = async (data: {
    data: {
      comment: number
      reaction: number
      dtl_user: number
    }
  }) => {
    return await addNewUserReaction(data).unwrap()
  }
  const deleteNewUserReactionHandler = async (data: {
    commentId: number
    reactionId: number
    userId: number
  }) => {
    return await deleteNewUserReaction(data).unwrap()
  }

  const handleClick = async () => {
    if (!loggedInUser) {
      //router to push on login page
      router.push('/auth/login')
      return
    }
    if (inputStr && inputStr.length) {
      const data = {
        data: {
          content: inputStr,
          comment_author: loggedInUser.id,
          article: articleId,
          parent_comment: id,
        },
      }
      const commentD = await postComment(data)
      if (commentD) {
        // aa('clickedObjectIDs', {
        //   userToken: loggedInUser.id,
        //   index:
        //     getCookie('lang') == 'En'
        //       ? 'search_articles'
        //       : 'mx_search_articles',
        //   eventName: 'clickedObjectIDs',
        //   objectIDs: [articleId + ''],
        //   headers: {
        //     'X-Algolia-Application-Id':
        //       process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
        //     'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
        //   },
        // })

        aa('setUserToken', loggedInUser.toString())
        aa('convertedObjectIDs', {
          userToken: '' + loggedInUser.id.toString(),
          index:
            getCookie('lang') == 'En'
              ? 'search_articles'
              : 'mx_search_articles',
          eventName: 'article_comment',
          objectIDs: [id + ''],
          headers: {
            'X-Algolia-Application-Id':
              process.env.NEXT_PUBLIC_ALGOLIA_APP + '',
            'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_API_KEY + '',
          },
        })

        const childrenArr: CommentClientInterface[] = childComment
        childrenArr.unshift({
          id: commentD.data.id,
          content: inputStr,
          publishedAt: commentD.data.attributes.createdAt,
          type: 'child',
          parentId: id,
          comment_author: {
            id: loggedInUser.id,
            avatar: '',
            username: loggedInUser.username,
          },
          children: [],
          userWithReaction: [],
          articleId: articleId,
        })

        setCommentData({ ...commentData, children: childrenArr })
        setInputStr('')
        setReplyActive(false)
      }
    }
  }
  const handleEditClick = async () => {
    const data = {
      data: {
        id: id,
        content: inputStr,
      },
    }

    const response = await updateComment(data)

    if (response) {
      setCommentData({
        ...commentData,
        content: inputStr,
      })
      setReplyActive(false)
      setEditMode(false)
      setInputStr('')
    }
  }

  const editHandler = () => {
    setReplyActive(true)
    setEditMode(true)
  }

  const deleteHandler = async () => {
    const response = await delMultipleComments({ id })

    if (response) {
      setIsDeleted(true)
      setCommentData({ ...commentData, children: [] })
    }
  }
  const checkAndSaveEmojiReaction = async (e: {
    emoji: string
    unified: string
  }) => {
    if (!loggedInUser) {
      return
    }
    let emojiId = -1
    const emoji = await findEmojiHandler(e.unified)

    if (emoji.data && emoji.data.length == 0) {
      const emojiData = {
        data: {
          emoji: e.emoji,
          unified: e.unified,
        },
      }
      const newEmoji = await createEmojiHandler(emojiData)
      if (newEmoji) {
        emojiId = newEmoji.data.id
      }
    } else {
      emojiId = emoji.data[0].id
    }

    const copyArray = emojiWithUser
    const alreadyExistedEmoji = copyArray.find(
      (curr) => curr.emoji.unified == e.unified
    )

    if (alreadyExistedEmoji) {
      const emojiExistWithSameUser = alreadyExistedEmoji.users.find(
        (curr) => curr.userId == loggedInUser?.id
      )

      if (emojiExistWithSameUser) {
        const updatedUsers = copyArray.map((curr) => {
          if (curr.emoji.unified === e.unified) {
            return {
              ...curr,
              users: curr.users.filter(
                (curr) => curr.userId != loggedInUser?.id
              ),
            }
          } else {
            return curr
          }
        })

        const finalArr = updatedUsers.filter((curr) => curr.users.length > 0)
        const deleteUser = await deleteNewUserReactionHandler({
          commentId: id,
          reactionId: emojiId,
          userId: loggedInUser.id,
        })
        if (deleteUser) {
          setEmojiWithUser(finalArr)
        }
      } else {
        const updatedUsers = copyArray.map((curr) => {
          if (curr.emoji.unified === e.unified) {
            if (true) {
              return {
                ...curr,
                users: [
                  ...curr.users,
                  { userId: loggedInUser ? loggedInUser.id : -1 },
                ],
              }
            }
          } else {
            return curr
          }
        })
        const data = {
          data: {
            comment: id,
            reaction: emojiId,
            dtl_user: loggedInUser.id,
          },
        }
        const response = await addNewUserReactionHandler(data)
        if (response) {
          setEmojiWithUser(updatedUsers)
        }
      }
    } else {
      const data = {
        data: {
          comment: id,
          reaction: emojiId,
          dtl_user: loggedInUser.id,
        },
      }
      const response = await addNewUserReactionHandler(data)
      if (response) {
        copyArray.push({
          emoji: {
            name: e.emoji,
            unified: e.unified,
          },
          users: [{ userId: loggedInUser.id }],
        })
        setEmojiWithUser(copyArray)
      }
    }
    setEmojiPickerShown(false)
  }
  const onEmojiClick = (e: { emoji: string; unified: string }) => {
    setInputStr((prev) => prev + e.emoji)
  }
  copyEmojiWithUser = emojiWithUser
  const replyClick = () => setReplyActive(!replyActive)
  return (
    <>
      {!isDeleted ? (
        <>
          <Grid
            container
            spacing={3}
            className={
              type === 'child'
                ? classes.childCommentContainer
                : classes.parentCommentContainer
            }
          >
            <Grid item md={1} sm={1} xs={1}>
              <Avatar
                alt={comment_author.username}
                src={comment_author.avatar}
              />
            </Grid>
            <Grid
              item
              md={11}
              sm={10.5}
              xs={10.5}
              sx={{
                '&>*': {
                  marginBottom: '10px',
                },
                paddingLeft: {
                  xs: '30px !important',
                  sm: '5px !important',
                  md: '10px !important',
                },
              }}
            >
              <Grid container className={classes.userNameContainer}>
                <Typography className={classes.userName}>
                  {comment_author.username}
                </Typography>
                <Grid className={classes.dot}></Grid>
                <Moment fromNow className={classes.timeAgo}>
                  {publishedAt}
                </Moment>
              </Grid>
              <Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.commentContentContainer}
                >
                  <Grid item md={10.5} sm={10.2} xs={9.1}>
                    <Typography className={classes.commentContent}>
                      {content}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.menuRoot}>
                    {loggedInUser && comment_author.id == loggedInUser.id ? (
                      <CustomMenu
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        className=""
                        contentHead={<HorizontalIcon />}
                      />
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    alignItems: 'center',
                    paddingTop: '10px',
                    '&>*': {
                      marginRight: '15px',
                    },
                  }}
                >
                  <Grid sx={{ position: 'relative' }}>
                    {loggedInUser ? (
                      <Grid
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setEmojiPickerShown(!emojiPickerShown)}
                      >
                        <Emoji />
                      </Grid>
                    ) : (
                      ''
                    )}

                    {emojiPickerShown ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          zIndex: 100,
                          // left: {
                          //   sm: 0,
                          // },
                          '& .EmojiPickerReact': {
                            left: {
                              xs: '0px !important',
                            },
                            width: {
                              xs: '280px !important',
                              sm: '320px !important',
                              md: '350px !important',
                              lg: '350px',
                            },
                            height: {
                              xs: '400px !important',
                              sm: '400px !important',
                              md: '450px !important',
                              lg: '450px',
                            },
                            '& .epr-main': {
                              position: 'absolute !important',
                              zIndex: 10,
                            },
                          },
                        }}
                      >
                        <EmojiPicker
                          onEmojiClick={(e) => checkAndSaveEmojiReaction(e)}
                          theme={Theme.DARK}
                          searchDisabled={true}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                  {copyEmojiWithUser ? (
                    <EmojiContainer
                      onClick={(e) => checkAndSaveEmojiReaction(e)}
                      content={copyEmojiWithUser}
                    />
                  ) : (
                    ''
                  )}

                  <Grid
                    className={`${classes.replyContainer} ${
                      replyActive && classes.replyActive
                    }`}
                    onClick={replyClick}
                  >
                    {lang === 'En' ? 'Reply' : 'Responder'}
                  </Grid>
                  {replyActive ? (
                    <Grid sx={{ width: '100%' }}>
                      <Grid
                        container
                        item
                        className={classes.replyInputFieldContainer}
                      >
                        <RoundTextField
                          variant="outlined"
                          fullWidth
                          placeholder={
                            lang === 'En'
                              ? 'Add a reply...'
                              : 'Añadir una respuesta...'
                          }
                          className={classes.roundTextFieldRoot}
                          value={inputStr}
                          onChange={(e) => setInputStr(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Grid
                                  sx={{ position: 'relative' }}
                                  className={
                                    classes.roundTextFieldIconContainer
                                  }
                                  onClick={() =>
                                    setShowEmojiPicker(!showEmojiPicker)
                                  }
                                >
                                  <Emoji />
                                  {showEmojiPicker ? (
                                    <Grid
                                      sx={{
                                        position: 'absolute',
                                        zIndex: 100000,
                                        right: 10,
                                        top: 45,
                                        '& .aside.EmojiPickerReact.epr-main': {
                                          position: 'absolute !important',
                                          zIndex: 10,
                                        },
                                      }}
                                    >
                                      <EmojiPicker
                                        onEmojiClick={(e) => onEmojiClick(e)}
                                        theme={Theme.DARK}
                                        searchDisabled={true}
                                      />
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                                {editMode ? (
                                  <ButtonComponent
                                    className={classes.publishBtn}
                                    text={lang === 'En' ? 'EDIT' : 'EDITEDITAR'}
                                    type="text"
                                    onClick={handleEditClick}
                                  />
                                ) : (
                                  <ButtonComponent
                                    className={classes.publishBtn}
                                    text={lang === 'En' ? 'SEND' : 'ENVIAR'}
                                    type="text"
                                    onClick={handleClick}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {childComment && childComment.length
            ? childComment.map((curr, index) => (
                <CommentBody key={index} comment={curr} />
              ))
            : ''}
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default React.memo(CommentBody)
