import React from 'react'
import { Grid, Typography } from '@mui/material'
import ZenIcon from 'src/assets/svgs/icons/articleDetail/zenIcon'
import CustomizedSwitch from 'src/components/shared/atoms/switch'
import useStyles from './Style'

interface Props {
  handleClick: () => void
}
const ZenToggleBar = ({ handleClick }: Props) => {
  const { classes } = useStyles()
  const [lang, setLang] = React.useState<string>()
  React.useEffect(() => {
    if (
      typeof window === 'object' &&
      (window.location.href.includes('mx') ||
        window.location.href.includes('quit') ||
        window.location.href.includes('quiat'))
    ) {
      localStorage.setItem('lang', 'Es')
      setLang('Es')
    } else {
      localStorage.setItem('lang', 'En')
      setLang('En')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window === 'object' ? window.location.href : null])
  return (
    <Grid container className={classes.zenModeTogglerContainer}>
      <Grid item className={classes.zenModeTogglerIcon}>
        <ZenIcon />
      </Grid>
      <Grid item>
        <Typography className={classes.zenModeTogglerText}>
          {lang === 'En' ? 'ZEN MODE' : 'MODO ZEN'}
        </Typography>
      </Grid>
      <Grid item className={classes.zenModeTogglerBtn}>
        <CustomizedSwitch handleClick={handleClick} />
      </Grid>
    </Grid>
  )
}

export default ZenToggleBar
