import { Grid, Typography } from '@mui/material'
import React from 'react'
import useStyles from './Style'

interface IProps {
  content: {
    emoji: { name: string; unified: string }
    users: { userId: number }[]
  }[]
  onClick: (e: { emoji: string; unified: string }) => void
}

const EmojiContainer: React.FC<IProps> = ({ content, onClick }) => {
  const { classes } = useStyles()
  return (
    <>
      {content && content.length > 0
        ? content.map((curr, index) => (
            <Grid
              onClick={() =>
                onClick({ emoji: curr.emoji.name, unified: curr.emoji.unified })
              }
              key={index}
              className={classes.emojiCountContainer}
              sx={{ display: 'flex', cursor: 'pointer' }}
            >
              <Grid className={classes.fireIconContainer}>
                {curr.emoji.name}
              </Grid>
              <Typography>{curr.users.length}</Typography>
            </Grid>
          ))
        : ''}
    </>
  )
}

export default EmojiContainer
