/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from 'react'
import { Grid } from '@mui/material'
import useStyles from './Style'
import {
  useAddRatingArticleMutation,
  useUpdateRatingArticleMutation,
} from 'src/store/ApiCall/articleAPI'
import { useRouter } from 'next/router'

interface IProps {
  content: {
    avg: number
    user:
      | {
          id: number
          userId: number
          rating: string
        }
      | undefined
  }

  articleId: number
}

const Rating: React.FC<IProps> = ({ content, articleId }) => {
  const router = useRouter()
  // const loggedInUserJSON = localStorage.getItem('dtl_token')
  // let loggedInUser: { id: number; username: string } | undefined = undefined

  // if (loggedInUserJSON) {
  //   loggedInUser = JSON.parse(loggedInUserJSON)
  // }

  const [loggedInUser, setLoggedInUser] = React.useState<any>()

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON = localStorage.getItem('dtl_token')

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  const { classes } = useStyles()
  const [activeLi, setActiveLi] = useState([false, false, false, false, false])
  const [lastIndex, setLastIndex] = useState(-1)
  const ratingRef = useRef(null)
  const [addRating] = useAddRatingArticleMutation()
  const [updateRating] = useUpdateRatingArticleMutation()
  const [data, setData] = useState(content)

  useEffect(() => {
    if (content) {
      setData(content)
    }
  }, [content])
  async function addRatingHandler(data: {
    data: {
      article: number
      dtl_user: number
      rating: string
    }
  }) {
    return await addRating(data).unwrap()
  }
  async function updateRatingHandler(data: {
    article: number
    dtl_user: number
    rating: string
  }) {
    return await updateRating(data).unwrap()
  }

  useEffect(() => {
    if (content) {
      switch (Math.round(content.avg)) {
        case 1:
          setActiveLi([true, false, false, false, false])
          break
        case 2:
          setActiveLi([false, true, false, false, false])
          break
        case 3:
          setActiveLi([false, false, true, false, false])
          break
        case 4:
          setActiveLi([false, false, false, true, false])
          break
        case 5:
          setActiveLi([false, false, false, false, true])
          break
        default:
          setActiveLi([false, false, false, false, false])
          break
      }
    }
  }, [content])

  const handleClick = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: any,
    liIndex: number
  ) => {
    if (!loggedInUser) {
      router.push('/auth/login')
      return
    }
    let ratingLi = ''

    switch (liIndex) {
      case 0:
        ratingLi = 'one'
        break
      case 1:
        ratingLi = 'two'
        break
      case 2:
        ratingLi = 'three'
        break
      case 3:
        ratingLi = 'four'
        break
      case 4:
        ratingLi = 'five'
        break
      default:
        break
    }

    if (data?.user) {
      //upate api
      const queryVariable = {
        article: articleId,
        dtl_user: loggedInUser.id,
        rating: ratingLi,
      }
      const res = await updateRatingHandler(queryVariable)
      if (res) {
        setData({
          avg: content ? content.avg : 0,
          user: {
            id: res.id,
            userId: loggedInUser.id,
            rating: ratingLi,
          },
        })
      }
    } else {
      const queryVariable = {
        data: {
          article: articleId,
          dtl_user: loggedInUser.id,
          rating: ratingLi,
        },
      }
      //add api
      const res = await addRatingHandler(queryVariable)
      if (res) {
        setData({
          avg: content ? content.avg : 0,
          user: {
            id: res.id,
            userId: loggedInUser.id,
            rating: ratingLi,
          },
        })
      }
    }
    const element = e.target
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let li: any
    if (element.parentNode) {
      li = element.parentNode.parentNode as HTMLLIElement
    }
    if (element.tagName == 'LI') {
      li = e.target
    }

    const ul = li.parentNode as HTMLUListElement

    const rating = ul.parentNode as HTMLElement

    const last = ul.querySelector('.current') as HTMLLIElement

    let liActiveArr = activeLi
    setLastIndex(liActiveArr.indexOf(true))

    if (
      !rating.classList.contains('animate-left') &&
      !rating.classList.contains('animate-right')
    ) {
      last?.classList.remove('current')

      Array.from(ul.children).forEach((current: any) => {
        current.classList.toggle('active', liIndex > current.index)
      })
      rating.classList.add(
        liIndex > lastIndex ? 'animate-right' : 'animate-left'
      )
      rating.style.setProperty('--x', `${li.offsetLeft}px`)
      li.classList.add('move-to')
      last?.classList.add('move-from')
      setTimeout(() => {
        li.classList.add('current')
        liActiveArr = [false, false, false, false, false]
        liActiveArr[liIndex] = true
        setActiveLi(liActiveArr)
        li.classList.remove('move-to')
        last?.classList.remove('move-from')
        rating.classList.remove('animate-left', 'animate-right')
      }, 800)
    }
  }
  return (
    <Grid>
      <nav ref={ratingRef} className={`${classes.rating}`}>
        <ul>
          <li
            onClick={(e) => handleClick(e, 0)}
            className={activeLi[0] ? 'current' : ''}
          >
            <svg>
              <use xlinkHref="#star"></use>
            </svg>
          </li>
          <li
            onClick={(e) => handleClick(e, 1)}
            className={activeLi[1] ? 'current' : ''}
          >
            <svg>
              <use xlinkHref="#star"></use>
            </svg>
          </li>
          <li
            onClick={(e) => handleClick(e, 2)}
            className={activeLi[2] ? 'current' : ''}
          >
            <svg>
              <use xlinkHref="#star"></use>
            </svg>
          </li>
          <li
            onClick={(e) => handleClick(e, 3)}
            className={activeLi[3] ? 'current' : ''}
          >
            <span></span>
            <svg>
              <use xlinkHref="#star"></use>
            </svg>
          </li>
          <li
            onClick={(e) => handleClick(e, 4)}
            className={activeLi[4] ? 'current' : ''}
          >
            <span></span>
            <svg>
              <use xlinkHref="#star"></use>
            </svg>
          </li>
        </ul>
        <div>
          <span>
            <svg>
              <use xlinkHref="#star"></use>
            </svg>
          </span>
        </div>
      </nav>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol
          id="star"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 34"
        >
          <path
            fill="currentColor"
            d="M19.6859343,0.861782958 L24.8136328,8.05088572 C25.0669318,8.40601432 25.4299179,8.6717536 25.8489524,8.80883508 L34.592052,11.6690221 C35.6704701,12.021812 36.2532905,13.1657829 35.8938178,14.2241526 C35.8056709,14.4836775 35.6647294,14.7229267 35.4795411,14.9273903 L29.901129,21.0864353 C29.5299163,21.4962859 29.3444371,22.0366367 29.3872912,22.5833831 L30.1116131,31.8245163 C30.1987981,32.9368499 29.3506698,33.9079379 28.2172657,33.993502 C27.9437428,34.0141511 27.6687736,33.9809301 27.4085205,33.8957918 L18.6506147,31.0307612 C18.2281197,30.8925477 17.7713439,30.8925477 17.3488489,31.0307612 L8.59094317,33.8957918 C7.51252508,34.2485817 6.34688429,33.6765963 5.98741159,32.6182265 C5.90066055,32.3628116 5.86681029,32.0929542 5.88785051,31.8245163 L6.61217242,22.5833831 C6.65502653,22.0366367 6.46954737,21.4962859 6.09833466,21.0864353 L0.519922484,14.9273903 C-0.235294755,14.0935658 -0.158766688,12.8167745 0.690852706,12.0755971 C0.899189467,11.8938516 1.14297067,11.7555303 1.40741159,11.6690221 L10.1505113,8.80883508 C10.5695458,8.6717536 10.9325319,8.40601432 11.1858308,8.05088572 L16.3135293,0.861782958 C16.9654141,-0.0521682813 18.2488096,-0.274439442 19.1800736,0.365326425 C19.3769294,0.500563797 19.5481352,0.668586713 19.6859343,0.861782958 Z"
          ></path>
        </symbol>
        <path
          className="shadow"
          d="M18.7022469,29.7633426 L29.1611722,33.6861584 C28.8859085,33.8576358 28.5650147,33.9672494 28.2172657,33.993502 C27.9437428,34.0141511 27.6687736,33.9809301 27.4085205,33.8957918 L18.6506147,31.0307612 C18.2281197,30.8925477 17.7713439,30.8925477 17.3488489,31.0307612 L8.59094317,33.8957918 C7.98083887,34.0953792 7.34281791,33.9989813 6.83864817,33.6859784 L17.2977531,29.7633426 C17.7505234,29.5935537 18.2494766,29.5935537 18.7022469,29.7633426 Z"
        ></path>
      </svg>
    </Grid>
  )
}

export default Rating
