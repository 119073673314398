import React from 'react'
import { Grid, Typography } from '@mui/material'
import fbIcon from 'src/assets/svgs/fb-blue.svg'
import fbGrey from 'src/assets/svgs/fb-grey.svg'
import twitterIcon from 'src/assets/svgs/twitter.svg'
import twitterGrey from 'src/assets/svgs/twitter-grey.svg'
import sharelinkIcon from 'src/assets/svgs/sharelink.svg'
import Image from 'next/image'
import useStyles from './Style'
import { useSnackbar } from 'notistack'

import { FacebookShareButton, TwitterShareButton } from 'react-share'

interface shareArticleProps {
  flex?: boolean
  zenMode: boolean
}
const ShareArticle: React.FC<shareArticleProps> = ({ zenMode, flex }) => {
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [lang, setLang] = React.useState<string>()
  React.useEffect(() => {
    if (
      typeof window === 'object' &&
      (window.location.href.includes('mx') ||
        window.location.href.includes('quit') ||
        window.location.href.includes('quiat'))
    ) {
      localStorage.setItem('lang', 'Es')
      setLang('Es')
    } else {
      localStorage.setItem('lang', 'En')
      setLang('En')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window === 'object' ? window.location.href : null])
  const copyLink = () => {
    navigator.clipboard.writeText(
      typeof window === 'object' ? window.location.href : ''
    )
    enqueueSnackbar('Link Copied', {
      variant: 'success',
    })
  }
  return (
    <Grid
      container
      alignItems="center"
      className={classes.shareArticleContainer}
      sx={{
        display: {
          md: flex ? 'flex' : 'block',
          xs: 'none',
        },
        justifyContent: zenMode ? 'center' : 'left',
      }}
    >
      <Grid item>
        <Typography className={classes.shareArticleText}>
          {lang === 'En' ? 'SHARE ARTICLE' : 'COMPARTIR ARTÍCULO'}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          '& > *': {
            marginRight: '10px',
          },
        }}
      >
        <Grid item>
          <FacebookShareButton
            url={typeof window === 'object' ? window.location.href : ''}
          >
            <Image
              alt="fbIcon"
              src={!zenMode ? fbIcon?.src : fbGrey?.src}
              className={classes.fbLogo}
              width={32}
              height={32}
            />
          </FacebookShareButton>
        </Grid>
        <Grid item>
          <TwitterShareButton
            url={typeof window === 'object' ? window.location.href : ''}
          >
            <Image
              alt="twitter"
              src={!zenMode ? twitterIcon?.src : twitterGrey?.src}
              className={classes.twitterLogo}
              width={32}
              height={32}
            />
          </TwitterShareButton>
        </Grid>
        <Grid item>
          <Image
            alt="share link"
            src={sharelinkIcon?.src}
            className={classes.shareLinkLogo}
            width={32}
            height={32}
            onClick={copyLink}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ShareArticle
