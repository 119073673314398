import React from 'react'
import { Grid, Typography } from '@mui/material'
import useStyles from './Style'
import Rating from './rating'

interface IProps {
  content: {
    avg: number
    user:
      | {
          id: number
          userId: number
          rating: string
        }
      | undefined
  }

  articleId: number
}
const RateArticle: React.FC<IProps> = ({ content, articleId }) => {
  const [lang, setLang] = React.useState<string>()
  React.useEffect(() => {
    if (
      typeof window === 'object' &&
      (window.location.href.includes('mx') ||
        window.location.href.includes('quit') ||
        window.location.href.includes('quiat'))
    ) {
      localStorage.setItem('lang', 'Es')
      setLang('Es')
    } else {
      localStorage.setItem('lang', 'En')
      setLang('En')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeof window === 'object' ? window.location.href : null])
  const { classes } = useStyles()
  return (
    <Grid container direction="column" className={classes.rootContainer}>
      <Grid item>
        <Typography variant="h3" className={classes.rateArticleHeading}>
          {lang === 'En' ? 'RATE THIS ARTICLE' : 'CALIFICAR ESTE ARTÍCULO'}
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item>
          <Rating articleId={articleId} content={content} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RateArticle
