import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    width: '100%',
    padding: theme.spacing(10, 17.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
    marginBottom: theme.spacing(20),
    textAlign: 'center',
  },
  rateArticleHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 800,
    fontSize: '48px',
    lineHeight: '84px',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '84px',
    },
  },
}))

export default useStyles
