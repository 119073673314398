/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Grid } from '@mui/material'
import useStyles from 'src/styles/pages/article/articleDetail/Style'
import HeroHeader from 'src/components/pages/articleDetail/heroHeader'
import MainContent from 'src/components/pages/articleDetail/mainContent'
import RateArticle from 'src/components/pages/articleDetail/rateArticle'
import ConfidentialSupport from 'src/components/pages/articleDetail/confidentialSupport'
import CommentWithReply from 'src/components/pages/articleDetail/comments'
import {
  // useGetArticleMutation,
  // useGetArticlePageQuery,
  useGetCommentsMutation,
} from 'src/store/ApiCall/articleAPI'
import MeetNewFriend from 'src/components/shared/modules/meetNewFriends/MeetNewFriends'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import {
  GetCommentsInterface,
  GetAllArticles,
  HeadingTextButton,
} from 'src/types/store'
import { MeetNewFriend as MeetNewFriendContent } from 'src/types/components/shared/atom'
import ArticleSlider from 'src/components/shared/modules/articleSlider/ArticleSlider'
import HeadTag from 'src/components/layout/head/articleHead'

interface btnData {
  id: number
  content: string
  href: string
}
interface confidentialSupport {
  heading: string
  para: string
  btnDetail: btnData[]
}

interface headerContent {
  title: string
  published: string
  author: { name: string; img: string }
  tags: { content: string; id: number }[]
  headerImage: {
    name: string
    url: string
  }
  brandInfo: { name: string; logo: string }
}

interface IProps {
  data?: any
  articleDetailData?: any
  isLoading: boolean
}
const ArticleDetail: React.FC<IProps> = ({
  data,
  articleDetailData,
  isLoading,
}) => {
  const zenMode = useSelector((state: RootState) => state.zenModeState.zenMode)
  const router = useRouter()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loggedInUser, setLoggedInUser] = React.useState<any>()

  React.useEffect(() => {
    if (window) {
      const loggedInUserJSON = localStorage.getItem('dtl_token')

      if (loggedInUserJSON) {
        setLoggedInUser(JSON.parse(loggedInUserJSON).id)
      }
    }
  }, [])

  const slug = router?.query?.tag
  // const { data } = useGetArticlePageQuery('articleDetail')
  const [articleData, setArticleData] = useState<GetAllArticles>()
  const [headerContent, setHeaderContent] = useState<headerContent>()
  const [mainContent, setMainContent] = useState<string>('')

  const [rating, setRating] = useState<{
    avg: number
    user:
      | {
          id: number
          userId: number
          rating: string
        }
      | undefined
  }>({
    avg: 0,
    user: undefined,
  })
  const [confidentialSupport, setConfidentialSupport] =
    useState<confidentialSupport>()
  const [popularHeader, setPopularHeader] = useState<HeadingTextButton>()

  const [meetTeam, setMeetTeam] = useState<MeetNewFriendContent>()
  const [commentData, setCommentData] = useState<GetCommentsInterface[] | []>(
    []
  )

  const [commentsCount, setCommentsCount] = useState<number>(0)
  const [articleGifSrc, setArticleGifSrc] = useState<string>('')
  // const [getArticle, { isLoading }] = useGetArticleMutation()
  const [getComments] = useGetCommentsMutation()

  async function getCommentsDetail(idsArr: { id: number[] }) {
    const data = await getComments(idsArr).unwrap()
    if (data) {
      setCommentData(data)
    }
  }
  // async function getArticleDetail() {
  //   const data = await getArticle(slug).unwrap()

  //   if (data && data.data && data.data[0] && !isLoading) {
  //     setArticleData(data)
  //     const {
  //       tags,
  //       title,
  //       publishedAt,
  //       author,
  //       images,
  //       description,
  //       //  description1,
  //       comments,
  //       brandName,
  //       brandLogo,
  //       article_ratings,
  //       article_comment_count,
  //       metaTitle,

  //       metaDescription,
  //     } = data.data[0]?.attributes

  //     if (comments && comments.data && comments.data.length) {
  //       const ids = comments.data.map((curr) => curr.id)

  //       getCommentsDetail({ id: ids })
  //     }

  //     let name = ''
  //     let url = ''
  //     let authorImage = ''
  //     let authorName = ''
  //     if (images.data?.attributes) {
  //       name = images.data?.attributes.name
  //       url = images.data?.attributes.url
  //     }
  //     if (author.data?.attributes) {
  //       authorName = author.data?.attributes.Name
  //       authorImage = author.data
  //         ? author.data.attributes.Picture?.data?.attributes.url
  //         : ''
  //     }
  //     if (
  //       article_ratings &&
  //       article_ratings.data &&
  //       article_ratings.data.length
  //     ) {
  //       let sum = 0
  //       article_ratings.data.forEach((curr) => {
  //         switch (curr.attributes.rating) {
  //           case 'one':
  //             sum += 1
  //             break
  //           case 'two':
  //             sum += 2
  //             break
  //           case 'three':
  //             sum += 3
  //             break
  //           case 'four':
  //             sum += 4
  //             break
  //           case 'five':
  //             sum += 5
  //             break
  //           default:
  //             break
  //         }
  //       })

  //       const avg = sum / article_ratings.data.length

  //       const record = loggedInUser
  //         ? article_ratings.data.find(
  //             (curr) => curr.attributes?.dtl_user?.data?.id == loggedInUser?.id
  //           )
  //         : undefined
  //       if (record) {
  //         setRating({
  //           avg,
  //           user: {
  //             id: record.id,
  //             rating: record.attributes.rating,
  //             userId: record.attributes.dtl_user.data.id,
  //           },
  //         })
  //       } else {
  //         setRating({
  //           avg,
  //           user: undefined,
  //         })
  //       }
  //     }

  //     //set Main Content From Article Detail API response
  //     const tagsArr =
  //       tags.data && tags.data.length
  //         ? tags.data.map((curr) => {
  //             return { content: curr.attributes.topic, id: curr.id }
  //           })
  //         : []

  //     const header: headerContent = {
  //       title,
  //       published: publishedAt,
  //       author: { name: authorName, img: authorImage },
  //       tags: tagsArr,
  //       headerImage: { name, url },
  //       brandInfo: {
  //         name: brandName,
  //         logo: brandLogo.data ? brandLogo.data.attributes.url : '',
  //       },
  //     }
  //     setHeaderContent(header)

  //     setMainContent(description)
  //     if (article_comment_count) {
  //       setCommentsCount(article_comment_count)
  //     }
  //     if (metaTitle) setMetaTitle(metaTitle)
  //     if (metaDescription) setMetaDescription(metaDescription)
  //   } else router.push('/404')
  // }
  useEffect(() => {
    if (
      articleDetailData &&
      articleDetailData.data &&
      articleDetailData.data[0] &&
      !isLoading
    ) {
      setArticleData(articleDetailData)
      const {
        tags,
        title,
        publishedAt,
        author,
        images,
        description,
        //  description1,
        comments,
        brandName,
        brandLogo,
        article_ratings,
        article_comment_count,
      } = articleDetailData.data[0]?.attributes

      if (comments && comments.data && comments.data.length) {
        const ids = comments.data.map((curr: any) => curr.id)

        getCommentsDetail({ id: ids })
      }

      let name = ''
      let url = ''
      let authorImage = ''
      let authorName = ''
      if (images.data?.attributes) {
        name = images.data?.attributes.name
        url = images.data?.attributes.url
      }
      if (author.data?.attributes) {
        authorName = author.data?.attributes.Name
        authorImage = author.data
          ? author.data.attributes.Picture?.data?.attributes.url
          : ''
      }
      if (
        article_ratings &&
        article_ratings.data &&
        article_ratings.data.length
      ) {
        let sum = 0
        article_ratings.data.forEach((curr: any) => {
          switch (curr.attributes.rating) {
            case 'one':
              sum += 1
              break
            case 'two':
              sum += 2
              break
            case 'three':
              sum += 3
              break
            case 'four':
              sum += 4
              break
            case 'five':
              sum += 5
              break
            default:
              break
          }
        })

        const avg = sum / article_ratings.data.length

        const record = loggedInUser
          ? article_ratings.data.find(
              (curr: any) =>
                curr.attributes?.dtl_user?.data?.id == loggedInUser?.id
            )
          : undefined
        if (record) {
          setRating({
            avg,
            user: {
              id: record.id,
              rating: record.attributes.rating,
              userId: record.attributes.dtl_user.data.id,
            },
          })
        } else {
          setRating({
            avg,
            user: undefined,
          })
        }
      }

      //set Main Content From Article Detail API response
      const tagsArr =
        tags.data && tags.data.length
          ? tags.data.map((curr: any) => {
              return { content: curr.attributes.topic, id: curr.id }
            })
          : []

      const header: headerContent = {
        title,
        published: publishedAt,
        author: { name: authorName, img: authorImage },
        tags: tagsArr,
        headerImage: { name, url },
        brandInfo: {
          name: brandName,
          logo: brandLogo.data ? brandLogo.data.attributes.url : '',
        },
      }
      setHeaderContent(header)

      setMainContent(description)
      if (article_comment_count) {
        setCommentsCount(article_comment_count)
      }
    } else router.push('/404')
  }, [articleDetailData])
  // useEffect(() => {
  //   if (slug) {
  //     getArticleDetail()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [slug])

  useEffect(() => {
    if (data && data.data[0]?.attributes) {
      const content = data.data[0].attributes

      const {
        confidentialSupport: cs,
        meet: m,
        popularArticleListHeading,
        articleDetailGif,
      } = content

      //extracting confidential support data from content
      if (cs) {
        const confidentialSupport = {
          heading: cs.heading,
          para: cs.text,
          btnDetail: cs.button,
        }
        setConfidentialSupport(confidentialSupport)
      }
      if (articleDetailGif) {
        setArticleGifSrc(articleDetailGif.data?.attributes?.url)
      }

      //extracting meet team data from content
      if (m) {
        const meetTeam = {
          heading: m.heading,
          para: m.text,
          btnDetail: m.button[0],
        }
        setMeetTeam(meetTeam)
      }

      if (popularArticleListHeading) setPopularHeader(popularArticleListHeading)
    }
  }, [data])

  const { classes } = useStyles()

  return (
    <>
      <HeadTag
        title={articleDetailData?.data?.[0]?.attributes?.metaTitle}
        description={articleDetailData?.data?.[0]?.attributes?.metaDescription}
        articleID={slug}
        articleTitle={headerContent?.title}
        articleImage={headerContent?.headerImage.url}
      />
      <Grid
        sx={{
          marginTop: '90px',
          margin: 'auto',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {isLoading ? (
          'Loading...'
        ) : articleData ? (
          <Grid
            className={classes.articleDetailContainer}
            sx={{ '& img': { display: zenMode ? 'none' : 'inline' } }}
          >
            <HeroHeader headerContent={headerContent} zenMode={zenMode} />
            <MainContent
              articleGif={articleGifSrc}
              content={mainContent}
              zenMode={zenMode}
            />
            {true ? (
              <RateArticle
                articleId={Number(articleData.data[0].id)}
                content={rating}
              />
            ) : (
              ''
            )}

            <Grid className={classes.gradientContainer}>
              <Grid>
                {confidentialSupport ? (
                  <ConfidentialSupport
                    zenMode={zenMode}
                    content={confidentialSupport}
                  />
                ) : (
                  ''
                )}

                <CommentWithReply
                  articleId={Number(articleData.data[0].id)}
                  data={commentData}
                  comments_count={commentsCount}
                />
              </Grid>
            </Grid>
            {popularHeader ? (
              <ArticleSlider headerContent={popularHeader} zenMode={zenMode} />
            ) : (
              ''
            )}

            {meetTeam ? (
              <Grid className={classes.meetNewFriendContainer}>
                <MeetNewFriend content={meetTeam} zenMode={zenMode} />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  )
}

export default ArticleDetail
