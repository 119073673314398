import { lato } from '@styles/fonts'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  emojiCountContainer: {
    // width: '25px',
    padding: theme.spacing(0, 1),
    borderRadius: '7.5px',
    border: '1px solid #C5CED6',
    alignItems: 'center',
    '& .MuiTypography-body1': {
      fontFamily: lato.style.fontFamily,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '12px',
      marginLeft: theme.spacing(1.5),
    },
  },
  fireIconContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}))

export default useStyles
