import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'

const useStyles = makeStyles()((theme) => ({
  zenModeTogglerContainer: {
    background: '#1C1C1C',
    padding: theme.spacing(4, 3),
    borderRadius: '25.5px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '235px',
      margin: theme.spacing(8, 'auto'),
      padding: theme.spacing(2, 3),
    },
  },
  zenModeTogglerIcon: {
    width: '27px',
    height: '18px',
  },
  zenModeTogglerText: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20.16px',
    color: '#fff',
    textTransform: 'uppercase',
    marginLeft: theme.spacing(2.5),
  },
  zenModeTogglerBtn: {
    marginLeft: 'auto',
    '& .MuiFormControlLabel-root': {
      marginRight: '0px',
    },
  },
}))

export default useStyles
