import { pxToRem } from '@styles/function'
import { makeStyles } from 'tss-react/mui'
import mainImage from 'src/assets/images/confidential/conmain.jpg'
import noise from 'src/assets/images/campaigns/noise.png'
import { nunito_sans, lato } from '@styles/fonts'
const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    width: '100%',
    padding: theme.spacing(10, 17.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  confidentialContainer: {
    maxWidth: '793px',
    margin: '0 auto',
    marginBottom: pxToRem(20),
    padding: `${pxToRem(49)} ${pxToRem(48)}`,
    backgroundImage: `url(${mainImage?.src})`,
    position: 'relative',
    borderRadius: '40px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(5, 4),
    },
  },
  zenModeStyle: {
    backgroundImage: 'none',
    border: '1px solid black',
    maxWidth: '960px',
    padding: `${pxToRem(43)} ${pxToRem(30)}`,
  },
  noiseBg: {
    position: 'absolute',
    borderRadius: '40px',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${noise?.src})`,
  },

  EmojiImg: {
    width: '138px',
    height: '138px',
    [theme.breakpoints.down('sm')]: {
      width: '68px',
      height: '68px',
    },
  },
  confidentialHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 800,
    fontSize: pxToRem(36),
    lineHeight: pxToRem(40),
    color: 'white',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(43.65),
    },
  },
  zenModeHeading: {
    fontSize: pxToRem(64),
    lineHeight: pxToRem(83),
    textAlign: 'left',
    color: 'black',
  },

  confidentialPara: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16.8),
    color: 'white',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(16.8),
    },
  },
  zenModePara: {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
    textAlign: 'left',

    color: 'black',
  },
  getSupportBtnContainer: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  getSupportBtn: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21.82),
    color: 'white',
    textAlign: 'center',
    padding: theme.spacing(3.5, 6.5),
    background: '#1C1C1C',
    width: '100%',
  },
  learnMoreBtn: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21.82),
    color: 'black',
    textAlign: 'center',
    padding: theme.spacing(3.5, 6.5),
    background: 'transparent',
    border: '1px solid black',
  },
}))

export default useStyles
