import * as React from 'react'
import Head from 'next/head'
// import DTLLogo from 'src/assets/svgs/DTLLogo.svg'

interface HeadTagProps {
  title?: string | null | undefined
  description?: string | null | undefined
  image?: string
  articleID?: string | string[] | undefined
  articleTitle?: string
  articleImage?: string
}

const HeadTag: React.FC<HeadTagProps> = ({
  title,
  description,
  articleID = '',
  articleTitle = '',
  articleImage,
}) => {
  const [jsonLD] = React.useState({
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'WebSite',
        '@id': 'https://ditchthelabel.org/#website',
        url: 'https://ditchthelabel.org',
        name: title ? title : 'Ditch The Label',
        description: description
          ? description
          : 'Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems.',
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: 'https://ditchthelabel.org/search',
          },
        ],
        inLanguage: 'en-US',
      },
      {
        '@type': 'ImageObject',
        '@id': 'https://ditchthelabel.org/#primaryimage',
        inLanguage: 'en-US',
        url: '',
        width: 877,
        height: 319,
        caption: 'Ditch The Label Logo',
      },
      {
        '@type': 'WebPage',
        '@id': `https://ditchthelabel.org/${articleID}`,
        url: `https://ditchthelabel.org/${articleID}`,
        name: { articleTitle },
        isPartOf: { '@id': `https://ditchthelabel.org/${articleID}` },
        primaryImageOfPage: {
          '@id': articleImage ? articleImage : '/dtl-image.png',
        },
        datePublished: '2020-11-23T22:17:43+00:00',
        dateModified: '2021-04-27T16:23:57+00:00',
        description:
          'Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems.',
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: ['https://ditchthelabel.org/'],
          },
        ],
      },
    ],
  })
  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, viewport-fit=cover"
      />
      <meta name="robots" content="max-image-preview:large" />
      <title>{title ? title : process.env.NEXT_PUBLIC_META_TITLE}</title>

      {/* favicon */}
      <link
        rel="icon"
        type="image/x-icon"
        href={process.env.NEXT_PUBLIC_FAVICON}
      ></link>
      <meta
        name="description"
        content={
          description
            ? description
            : 'Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems.'
        }
      />
      <meta
        name="robots"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <link rel="canonical" href="https://ditchthelabel.org/" />

      {/* open graph description */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={
          title
            ? title
            : process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? 'Ditch the Label, mental health platform.'
            : 'QUITATE LAS ETIQUETAS'
        }
      />
      <meta
        property="og:description"
        content={
          description
            ? description
            : process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? 'For over 10 years, we’ve been on a mission to help young people overcome the issues that affect them the most. Whether that’s bullying, mental health, relationships, identity or the tough stuff in-between, we’re here. We provide digital safe spaces, direct support, education, campaigns and world-class research.'
            : 'Quitate las etiquetas, una plataforma de salud mental para ayudar a las personas a brindar orientación sobre problemas relacionados con la salud mental.'
        }
      />
      <meta property="og:url" content="https://ditchthelabel.org" />
      <meta
        property="og:site_name"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? 'Ditch The Label'
            : 'QUITATE LAS ETIQUETAS'
        }
      />
      <meta
        property="article:modified_time"
        content="2023-05-21T13:23:57+00:00"
      />
      <meta
        property="og:image"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />
      <meta property="og:image:width" content="768" />
      <meta property="og:image:height" content="400" />

      {/* social media */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? 'Ditch The Label'
            : 'QUITATE LAS ETIQUETAS'
        }
      />
      <meta
        name="twitter:description"
        content="Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems."
      />
      <meta
        name="twitter:image"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />

      <meta name="facebook:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? 'Ditch The Label'
            : 'QUITATE LAS ETIQUETAS'
        }
      />
      <meta
        name="twitter:description"
        content="Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems."
      />

      {/* JSON LD */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD) }}
      />
      <meta
        name="smartbanner:title"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? 'Ditch The Label'
            : 'QUITATE LAS ETIQUETAS'
        }
      />
      <meta
        name="smartbanner:author"
        content="Ditch the Label, a mental health platform to help people in providing guidance in mental health related problems."
      />
      <meta name="smartbanner:button" content="VIEW" />
      <meta name="smartbanner:enabled-platforms" content="android,ios" />
      <meta name="smartbanner:close-label" content="Close" />
      <meta name="smartbanner:api" content="true" />

      {/* apple & google meta tags */}
      <meta
        name="smartbanner:icon-apple"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />
      <meta
        name="smartbanner:button-url-apple"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />
      <meta
        name="apple-itunes-app"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />

      <meta
        name="smartbanner:icon-google"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />
      <meta
        name="smartbanner:button-url-google"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />

      <link rel="icon" href="" sizes="32x32" />
      <link rel="icon" href="" sizes="192x192" />
      <link
        rel="apple-touch-icon"
        href={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />
      <meta
        name="msapplication-TileImage"
        content={
          process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
            ? '/dtl-image.png'
            : '/qle-og.png'
        }
      />
    </Head>
  )
}

export default HeadTag
