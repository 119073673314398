import { makeStyles } from 'tss-react/mui'
import { lato, nunito_sans } from '@styles/fonts'
// import adSpace from 'src/assets/images/articleDetail/adSpace.jpg'
const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    width: '100%',
    padding: theme.spacing(10, 17.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  leftPanelContainer: {
    padding: theme.spacing(5, 0, 5, 0),
  },

  listItemButton: {
    paddingLeft: 0,
    marginBottom: '20px',
    '& .MuiListItemText-primary': {
      fontFamily: nunito_sans.style.fontFamily,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '21.82px',
      opacity: 0.7,
    },

    '&:hover': {
      fontStyle: '800',
      background: 'transparent',
    },

    '&.Mui-selected, &.Mui-selected:hover': {
      background: 'none',
      '& .MuiListItemText-primary': {
        opacity: 1,
      },
    },
  },
  zenModeContainer: {
    padding: theme.spacing(2, 0),
    borderTop: '1px solid rgba(0, 0, 0, 0.4)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
    '&> *': {
      margin: 0,
      borderRadius: '5px',
    },
  },
  midPanelContainer: {
    maxWidth: '812px',
    width: '100%',
    margin: 'auto',
    padding: theme.spacing(4),
    '& p': {
      fontFamily: lato.style.fontFamily,
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '21.6px',
      textAlign: 'left',
      marginTop: '8px',
    },

    '& h3': {
      fontFamily: nunito_sans.style.fontFamily,
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '43.65px',
      textAlign: 'left',
      width: '100%',
      overflow: 'hidden',
    },
    '& h1,h2,h4,h5,h6': {
      textAlign: 'left',
    },
    '& a': {
      color: '#935eff',
    },
    '& li': {
      textAlign: 'left',
    },
    '&.zenMode': {
      '& p': {
        textAlign: 'left',
      },
      '& h3,h1,h2,h4,h5,h6': {
        textAlign: 'left',
      },
    },
    '& img': {
      width: '100%',
      borderRadius: '24px',
      objectFit: 'cover',
    },
    '& iframe': {
      width: '100%',
      height: '500px',
      [theme.breakpoints.down('md')]: {
        height: '300px',
      },
    },
    '& video': {
      width: '100%',
      height: '500px',
      [theme.breakpoints.down('md')]: {
        height: '300px',
      },
    },
  },
  AdContainer: {
    width: '100%',
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      marginTop: '2rem',
    },
  },
}))

export default useStyles
