/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Grid, Typography } from '@mui/material'
import useStyles from './Style'
import { tags } from 'src/types/components/shared/atom'
import Tag from 'src/components/shared/atoms/Tag/Tag'
import Image from 'next/image'
import DTLLogo from 'src/assets/svgs/DTLLogo.svg'
import Cross from 'src/assets/svgs/articleDetail/Cross.svg'
// import asos from 'src/assets/svgs/asos1.svg'
import ZenToggleBar from '../zenToggleBar'
import ShareArticle from '../shareArticle'
import clsx from 'clsx'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { zenModeHandler } from 'src/store/reducer/zenSlice'
import { useRouter } from 'next/router'
import { getCookie } from 'cookies-next'
interface ArticleDetailProps {
  headerContent?: {
    title: string
    published: string
    tags: tags[]
    author: { name: string; img: string }
    headerImage: { name: string; url: string }
    brandInfo: { name: string; logo: string }
  }
  zenMode: boolean
}
const stringModifier = (str: string) => {
  const words = str.split(' ')
  const modifiedWords = words.map((word, index) => {
    if (index === words.length - 1) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
    return word.charAt(0).toUpperCase() + word.slice(1) + '-'
  })
  const modifiedStr = modifiedWords.join('')
  return modifiedStr
}

const HeroHeader: React.FC<ArticleDetailProps> = ({
  headerContent,
  zenMode,
}) => {
  const { classes } = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(zenModeHandler())
  }
  const lang = getCookie('lang')
  return (
    <Grid
      container
      className={clsx(classes.heroContainer, zenMode && classes.zenModeStyle)}
    >
      {/* <Grid
        sx={{
          display: { md: zenMode ? 'flex' : 'none', sm: 'none', xs: 'none' },
          alignItems: 'center',
        }}
        className={classes.articleCobrandOnZenMode}
      >
        <Grid item>
          <Image
            alt="asos"
            src={asos?.src}
            className={classes.articleCobrandOnZenModeImg}
            width={67}
            height={50}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.articleCobrandOnZenModePara}>
            This article is cobranded by asos
          </Typography>
        </Grid>
      </Grid> */}
      <Grid
        item
        md={zenMode ? 12 : 6}
        sm={12}
        sx={{
          textAlign: { md: zenMode ? 'center' : 'left', xs: 'center' },
          maxWidth: zenMode ? '650px' : '100%',
          marginLeft: zenMode ? 'auto' : '',
          marginRight: zenMode ? 'auto' : '',
          width: '100%',
        }}
      >
        <Grid
          sx={{
            maxWidth: zenMode ? '650px' : '100%',
            marginLeft: zenMode ? 'auto' : '',
            marginRight: zenMode ? 'auto' : '',
          }}
        >
          <Grid
            item
            container
            sx={{
              display: { md: 'none', xs: 'flex' },
              justifyContent: 'center',
            }}
          >
            <ZenToggleBar handleClick={handleClick} />
          </Grid>
          <Grid className={classes.dateContainer}>
            <Typography className={classes.date}>
              <Moment format="MMMM DD, YYYY">{headerContent?.published}</Moment>
            </Typography>
          </Grid>
          <Grid
            container
            className={classes.tagContainer}
            flexWrap="wrap"
            sx={{
              justifyContent: {
                md: zenMode ? 'center' : 'flex-start',
                xs: 'center',
              },
            }}
          >
            {headerContent?.tags
              ? headerContent.tags.length &&
                headerContent.tags.map((el, index) => (
                  <Tag
                    onClickHandler={false}
                    id={el.id}
                    background="white"
                    key={index}
                    content={el.content}
                    onClick={() => {
                      router.push(
                        `/${stringModifier(el.content) + '-Support-Hub'}`
                      )
                    }}
                  />
                ))
              : ''}
          </Grid>
          <Grid className={classes.articleHeadingContainer}>
            <Typography variant="h3" className={classes.articleHeading}>
              {headerContent?.title}
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={zenMode ? 0 : 3}
            item
            md={12}
            sx={{
              justifyContent: { md: zenMode ? 'center' : 'left', xs: 'center' },
            }}
            className={classes.autherContainer}
          >
            <Grid item>
              {headerContent?.author.img ? (
                <Grid className={classes.authorImgContainer}>
                  <Image
                    alt="auther img"
                    src={`${headerContent?.author.img}`}
                    className={classes.autherImg}
                    fill
                  />
                </Grid>
              ) : (
                ''
              )}
            </Grid>
            <Grid
              item
              sx={{
                textAlign: {
                  md: zenMode ? 'center' : 'left',
                  xs: zenMode ? 'center' : 'left',
                },
              }}
            >
              <Typography className={classes.auther}>
                {lang === 'En' ? 'Author' : 'Autor'}
              </Typography>
              <Typography
                variant="h5"
                className={classes.autherName}
                onClick={() => {
                  router.push({
                    pathname: '/articles',
                    query: { author: headerContent?.author.name },
                  })
                }}
              >
                {headerContent?.author.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: zenMode ? 'block' : 'none',
              maxWidth: '550.5px',
              borderTop: '1px solid rgba(0, 0, 0, 0.4)',
              margin: '50px auto 24px auto',
            }}
          ></Grid>
          <Grid
            className={`${classes.articleCobrand} ${
              headerContent?.brandInfo?.logo && classes.borderBottom
            }`}
            container
            spacing={3}
            sx={{
              display: {
                md: zenMode ? 'none' : 'flex',
                xs: zenMode ? 'none' : 'block',
                alignItems: 'center',
              },
            }}
          >
            {headerContent?.brandInfo?.logo && (
              <>
                <Grid item>
                  <Typography className={classes.articleCobrandText}>
                    This ARTICLE is cobranded by{' '}
                    {headerContent?.brandInfo.name
                      ? headerContent?.brandInfo.name
                      : 'sample'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    '&>*:not(:first-child)': {
                      marginLeft: '15px',
                    },

                    justifyContent: { md: 'left', xs: 'center' },
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <Image
                      alt="dtl logo"
                      src={DTLLogo?.src}
                      className={classes.dtlLogo}
                      width={91}
                      height={57}
                    />
                  </Grid>
                  <Grid item alignItems="center">
                    <Image
                      alt="cross"
                      src={Cross?.src}
                      width={12}
                      height={12}
                    />
                  </Grid>
                  {headerContent?.brandInfo?.logo && (
                    <Grid item>
                      <Image
                        alt={
                          headerContent?.brandInfo.name
                            ? headerContent?.brandInfo.name
                            : 'sample'
                        }
                        src={headerContent ? headerContent.brandInfo.logo : ''}
                        className={classes.simpleLogo}
                        width={90}
                        height={53}
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          <Grid>
            <ShareArticle zenMode={zenMode} flex={true} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
        sx={{
          width: '100%',
          display: {
            md: zenMode ? 'none' : 'block',
            xs: zenMode ? 'none' : 'block',
          },
        }}
      >
        {headerContent?.headerImage.url &&
        headerContent?.headerImage.url != '' ? (
          <Grid className={classes.heroImgContainer}>
            <Image
              alt="hero"
              src={`${headerContent?.headerImage.url}`}
              className={classes.heroImg}
              fill
            />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  )
}

export default HeroHeader
