import { lato, nunito_sans } from '@styles/fonts'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  commentRepliesContainer: {},
  parentCommentContainer: {
    marginTop: '20px',
    // border: '1px solid red',
  },
  childCommentContainer: {
    marginTop: '20px',
    marginLeft: '40px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-5px',
    },
  },
  userNameContainer: {
    alignItems: 'center',
    '&> *': {
      marginRight: theme.spacing(1.5),
    },
  },
  userName: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16.8px',
    letterSpacing: '1%',
  },
  dot: {
    width: '4px',
    height: '4px',
    background: '#D9D9D9',
    borderRadius: '50%',
  },
  timeAgo: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14.4px',
  },
  commentContentContainer: {
    // maxWidth: '590.25px',
    minWidth: '305px',
    display: 'flex',
    background: 'rgba(197, 206, 214, 0.29)',
    borderRadius: '8px',
    padding: theme.spacing(3.1),
    alignItems: 'flex-start',
  },
  commentContent: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16.8px',
    color: 'black',
    textAlign: 'left',
    // marginRight: theme.spacing(12),
  },
  menuRoot: {
    '& .MuiButtonBase-root': {
      paddingTop: '10px',
      height: '10px',
      display: 'flex',

      justifyContent: 'flex-end',

      '&:hover': {
        background: 'none',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  },
  emojiCountContainer: {
    // width: '25px',
    padding: theme.spacing(0, 1),
    borderRadius: '7.5px',
    border: '1px solid #C5CED6',
    alignItems: 'center',
    '& .MuiTypography-body1': {
      fontFamily: lato.style.fontFamily,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '12px',
      marginLeft: theme.spacing(1.5),
    },
  },
  fireIconContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  replyContainer: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    paddingLeft: theme.spacing(2),
    borderLeft: '1px solid #C5CED6',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  replyActive: {
    fontWeight: 800,
  },
  replyInputFieldContainer: {
    height: '60px',
    margin: theme.spacing(3, 0, 0, 0),
  },
  roundTextFieldRoot: {
    '& .MuiOutlinedInput-root': {
      '& .react-input-emoji--container': {
        border: 'none !important',
        zIndex: 10000000,
      },
      '& .react-emoji-picker--container': {
        top: 495,
      },
      '& .MuiInputBase-input ': {
        paddingLeft: theme.spacing(7),
      },
      height: '65px',
      '& fieldset': {
        height: '70px',
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
        borderWidth: '1px',
      },
    },
  },
  roundTextFieldIconContainer: {
    margin: theme.spacing(0, 2, 0, 2),
    cursor: 'pointer',
  },
  publishBtn: {
    border: 'none',
    background: 'black',
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21.82px',
    textTransform: 'uppercase',
    color: 'white',
    '&:hover': { background: 'black' },
    padding: theme.spacing(3.5, 6.5),
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '19px',
      padding: theme.spacing(3.5, 6),
    },
  },
}))

export default useStyles
